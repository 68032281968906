import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, List, ListItem, ListItemText, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';
import { useGetClientUserListQuery, useGetClientListQuery, useGetClientCutoffDateQuery, useUpdateClientCutoffDateMutation } from 'api/apiSlice';
import RTKQueryWrapper from 'components/DataFetchWrapper/RTKQueryWrapper';
import DataGridEditable from '../../../components/Tables/DataGridTable/DataGridEditable/DataGridEditable'; 
import UserDetailsModal from '../userManagement/UserDetailsModal'; 
import ClientDetailsModal from '../userManagement/ClientDetailsModal';
import UserLoginModal from '../userManagement/UserLoginModal';
import { useTranslation } from "react-i18next";

const UserManagement = () => {
  const { data: users = [], error, isLoading, refetch } = useGetClientUserListQuery();
  const { data: clients = [], isLoading: isClientsLoading, error: clientsError, refetch: refetchClients } = useGetClientListQuery();
  
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [modalMode, setModalMode] = useState('edit');
  const [selectedDate, setSelectedDate] = useState(null);
  const [originalDate, setOriginalDate] = useState(null);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const { t } = useTranslation();

  const { data: cutoffDate, refetch: refetchCutoffDate } = useGetClientCutoffDateQuery(null, {
    skip: false,
  });

  const [updateClientCutoffDate] = useUpdateClientCutoffDateMutation();

  const [selectedUserIdForLogins, setSelectedUserIdForLogins] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const handleOpenLoginModal = (userId) => {
    setSelectedUserIdForLogins(userId);
    setIsLoginModalOpen(true);
  };

  const handleCloseLoginModal = () => {
    setIsLoginModalOpen(false);
    setSelectedUserIdForLogins(null);
  };

  useEffect(() => {
    if (users.length > 0) {
      const formattedRows = users.map(user => ({
        id: user.user.id,
        email: user.user.email,
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        username: user.user.username,
        is_active: user.user.is_active,
        numberOfPages: user.settings?.pages?.length || 0,
      }));
      setRows(formattedRows);
    }
  }, [users]);

  useEffect(() => {
    if (cutoffDate && cutoffDate.max_date_override) {
      const initialDate = new Date(cutoffDate.max_date_override); 
      setOriginalDate(initialDate);
      setSelectedDate(initialDate);
      setIsDateChanged(false);
    }
  }, [cutoffDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setIsDateChanged(newDate !== originalDate);
  };

  const handleSaveDate = async () => {
    const formattedDate = selectedDate ? format(new Date(selectedDate), 'yyyy-MM-dd') : null;

    try {
      await updateClientCutoffDate({ id: selectedClientId, max_date_override: formattedDate });
      refetchCutoffDate();
    } catch (error) {
      console.error("Failed to update cutoff date:", error);
    }
  };

  const handleRemoveDate = async () => {
    try {
      await updateClientCutoffDate({ id: selectedClientId, max_date_override: "no_override" });
      refetchCutoffDate();
      setSelectedDate(null);
      setOriginalDate(null);
      setIsDateChanged(false);
    } catch (error) {
      console.error("Failed to remove cutoff date:", error);
    }
  };

  const handleProcessRowUpdate = async (oldRow, newRow) => {
    console.log("Updated Row:", newRow);
    return true;
  };

  const handleRowModeModelChange = (newModel) => {
    setRowModesModel(newModel);
  };

  const handleRowClick = (params) => {
    console.log("Row clicked", params);
    setSelectedUserId(params.id || params.row.id);
    setModalMode('edit');
    setIsUserModalOpen(true);
  };

  const handleAddUser = () => {
    setSelectedUserId(null);
    setModalMode('create');
    setIsUserModalOpen(true);
  };

  const handleAddClient = () => {
    setSelectedClientId(null);
    setModalMode('create');
    setIsClientModalOpen(true);
  };

  const handleClientClick = (client) => {
    console.log("Client clicked", client);
    setSelectedClientId(client.id);
    setModalMode('edit');
    setIsClientModalOpen(true);
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false);
    setSelectedUserId(null);
    refetch(); 
  };

  const handleCloseClientModal = () => {
    setIsClientModalOpen(false);
    setSelectedClientId(null);
    refetchClients(); 
  };

  const columns = [
    { field: 'email', headerName: 'Email', width: 250, editable: true },
    { field: 'first_name', headerName: 'First Name', width: 150, editable: true },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    { field: 'username', headerName: 'Username', width: 250, editable: true },
    { field: 'is_active', headerName: 'Active', width: 50 },
    { field: 'numberOfPages', headerName: 'Pages', width: 50 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={(event) => {
            event.stopPropagation(); // Stop the row click event
            handleOpenLoginModal(params.row.id);
          }}
        >
          View Logins
        </Button>
      ),
    },
  ];

  return (
    <Grid container spacing={3}>
      <Fade in={true} timeout={1000}>
        <Grid item xs={12} md={12} xl={12}>
          <Card>
            <CardContent>
              <Typography variant="h4">{t("userManagement.main.group")}</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t("userManagement.main.cutoffDate")}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
                />
              </LocalizationProvider>
              <Button
                onClick={handleSaveDate}
                color="primary"
                disabled={!isDateChanged}
                variant="contained"
                style={{ marginTop: '16px', marginRight: '8px' , marginLeft: '8px' }}
              >
                {t("userManagement.main.save")}
              </Button>
              <Button
                onClick={handleRemoveDate}
                color="secondary"
                variant="contained"
                style={{ marginTop: '16px' }}
              >
                {t("userManagement.main.removeCuttoff")}
              </Button>
            </CardContent>
            <CardContent>
              <Typography variant="h4">{t("userManagement.main.clients")}</Typography>
              <Button onClick={handleAddClient}>{t("userManagement.main.addClient")}</Button>
              <List>
                {clients?.map((client) => (
                  <ListItem
                    key={client.id}
                    button
                    onClick={() => handleClientClick(client)}
                    sx={{ borderBottom: '1px solid lightgray' }} 
                  >
                    <ListItemText 
                      primary={client.is_active ? client.display_name : `${client.display_name} (${t("userManagement.main.deleted")})`} 
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
          <Card>
            <CardContent sx={{ height: '500px' , marginBottom: '20px'}}>
              <Typography variant="h4">{t("userManagement.main.users")}</Typography>
              <Button onClick={handleAddUser}>{t("userManagement.main.addUser")}</Button>
              <RTKQueryWrapper query={{ data: rows, error, isLoading }}>
                {(data) => (
                  <DataGridEditable
                    rows={data}
                    columns={columns}
                    setRows={setRows}
                    rowModesModel={rowModesModel}
                    setRowModesModel={setRowModesModel}
                    processRowUpdate={handleProcessRowUpdate}
                    handleRowModeModelChange={handleRowModeModelChange}
                    onRowClick={handleRowClick}
                    showAddRowButton={false}
                  />
                )}
              </RTKQueryWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Fade>
      {isUserModalOpen && (
        <UserDetailsModal
          open={isUserModalOpen}
          handleClose={handleCloseUserModal}
          userId={selectedUserId}
          mode={modalMode}
          onSave={refetch}
        />
      )}
      {isClientModalOpen && (
        <ClientDetailsModal
          open={isClientModalOpen}
          handleClose={handleCloseClientModal}
          clientId={selectedClientId}
          mode={modalMode}
          onSave={refetchClients}
          users={users}
        />
      )}
      {isLoginModalOpen && (
        <UserLoginModal
          open={isLoginModalOpen}
          handleClose={handleCloseLoginModal}
          userId={selectedUserIdForLogins}
        />
      )}
    </Grid>
  );
};

export default UserManagement;