import React from "react";
import { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import { ICONS } from "assets/Icons";
import { useTranslation } from "react-i18next";
import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import { MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { FileDownloaderCell } from "../FileDownloaderCell";
import FileDownloaderIcon, {
  FileDownloaderIconURL,
} from "components/FileDownloadIcon/FileDownloadIcon";

import DirectInvestmentsTransactionsModal from "containers/pages/direct_investments/DirectInvestmentsTransactionModal";
import {
  GridActionsCellItem,
  getGridStringOperators,
  GridFilterInputValue,
} from "@mui/x-data-grid-premium";
import { parse_aws_url } from "../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
// Define your custom hook function

const excludeOperator = {
  label: "exclude",
  value: "exclude",
  getApplyFilterFn: (filterItem) => {
    if (
      !filterItem ||
      !filterItem.value ||
      !filterItem.field ||
      !filterItem.operator
    ) {
      return null;
    }

    return (params) => {
      return !params.value.includes(filterItem.value);
    };
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: { type: "string" },
};

export const useDataGridColumns = (columns) => {
  const [processedColumns, setProcessedColumns] = useState([]);
  const { t, i18n } = useTranslation();
  const clientCurrency = useSelector(
    (state) => state.user?.chosen_client?.client_info_fa?.currency
  );
  useEffect(() => {
    const filterOperators = (column) => {
      switch (column.type) {
        case "string":
          column.filterOperators = getGridStringOperators().concat([
            excludeOperator,
          ]);

          break;
        default:
      }
    };

    const valueSetter = (column) => {
      switch (column.type) {
        case "string":
          break;
        default:
      }
    };
    const headerNameTranslator = (column) => {
      if (column.headerName) {
        column.headerName = t(`dataGrid.columns.headerNames.${column.field}`, {
          clientCurrency,
        });
        column.description = t(
          `dataGrid.columns.descriptions.${column.description}`,
          { defaultValue: column.description ?? "" }
        );
      }
    };

    const customFormatters = (column) => {
      switch (column.type) {
        case "percent":
          column.type = "number";
          column.format = "percent";
          break;
        case "text":
          column.type = "string";
          break;
        case "number":
          column.valueFormatter = toNumber;
          break;
        case "date":
          column.valueFormatter = ({ value }) => {
            if (value instanceof Date) {
              return format(value, "dd-MM-yyyy");
            } else if (typeof value === "string") {
              return value && format(parseISO(value), "dd-MM-yyyy");
            }
            return value;
          };
          column.valueGetter = ({ value }) => {
            if (value instanceof Date) {
              return value;
            } else if (typeof value === "string") {
              return value && parseISO(value);
            }
            return value;
          };
          break;

          case "datetime":
            column.valueFormatter = ({ value }) => {
              if (value instanceof Date) {
                return format(value, "dd-MM-yyyy HH:mm:ss");
              } else if (typeof value === "string") {
                return value && format(parseISO(value), "dd-MM-yyyy HH:mm:ss");
              }
              return value;
            };
            column.valueGetter = ({ value }) => {
              if (value instanceof Date) {
                return value;
              } else if (typeof value === "string") {
                return value && parseISO(value);
              }
              return value;
            };
            break;

          case "boolean":
            column.valueFormatter = ({ value }) => {
              if (value === null || value === undefined) {
                return null; // Return null if the value is null or undefined
              }
      
              const isTrue = value === true || value === 'true' || value === 1;
              return isTrue ? <CheckCircleIcon style={{ color: green[500] }} /> : <CancelIcon style={{ color: red[500] }} />;
            };
            break;
            
        default:
          break;
      }
    };
    const customActionGetters = (column) => {
      if (column.type === "actions") {
        switch (column.actionType) {
          case "directInvestmentsTransactionsModal":
            column.getActions = DirectInvestmentsTransactionsModal;
            break;
          case "downloadDocuments":
            column.getActions = (params) => [
              <GridActionsCellItem
                icon={ICONS.add()}
                onClick={() => console.log(`Im row ID ${params.id}`)}
              ></GridActionsCellItem>,
            ];
            column.renderCell = documentDownloadIcon;
            break;
          case "saveEditDelete":
            if (!column.getActions) {
              column.getActions = (params, extra) => [
                <GridActionsCellItem
                  icon={ICONS.saveRounded()}
                  label="Save"
                  onClick={() =>
                    console.log(`Im row ID ${params?.id}`, params, extra)
                  }
                ></GridActionsCellItem>,
                <GridActionsCellItem
                  icon={ICONS.edit()}
                  label="Edit"
                  onClick={() => console.log(`Im row ID ${params.id}`)}
                ></GridActionsCellItem>,
                <GridActionsCellItem
                  icon={ICONS.delete()}
                  label="Delete"
                  onClick={() =>
                    console.log(`Im no longer row ID ${params.id}`)
                  }
                ></GridActionsCellItem>,
              ];
            }
            break;
        }
      }
    };
    const documentLinkDownload = (params) => {
      return (
        <a href={params.value} target="_blank">
          {parse_aws_url(params.value)}
        </a>
      );
    };
    const formatters = (column) => {
      switch (column.format) {
        case "percent":
          column.valueFormatter = toPercent;
          break;
        default:
          break;
      }
    };
    const customAggregationFormatters = (column) => {
      switch (column.aggregationValue) {
        case "percent":
          column.valueFormatter = toPercent;
          break;
        default:
          break;
      }
    };
    const customCellRender = (column) => {
      switch (column.field) {
        case "contractNotes":
        case "document":
          column.renderCell = documentDownloadIcon;
          break;
        case "attachment":
          column.renderCell = documentLinkDownload;
          break;
        case "documents":
          column.renderCell = downloadDocumentCell;
          break;
        case "transaction_info":
          column.renderCell = (params) => {
            if (params?.rowNode?.isAutoGenerated) {
              return null;
            }
            return null;
          };
          break;

        default:
          break;
      }
    };

    //simple formatter functions
    const toPercent = (params) => {
      const { value } = params;
      if (value === null || typeof value === "string" || !value) {
        return null;
      }
      return Intl.NumberFormat(i18n.language, {
        style: "percent",
        maximumFractionDigits: params?.colDef?.decimals || 1,
        minimumFractionDigits: params?.colDef?.decimals || 1,
      }).format(value);
    };
    const toNumber = (params) => {
      const { api, value, field } = params;
      const colDef = api.getColumn(field);
      if (value == null) {
        return null;
      }
      const valueFormatted = Intl.NumberFormat(i18n.language, {
        style: colDef?.style || "decimal",
        notation: colDef?.notation || "standard",
        maximumFractionDigits: colDef?.decimals || 0,
        minimumFractionDigits: colDef?.decimals || 0,
        currency: colDef?.currency,
        ...colDef?.formatOptions,
      }).format(params.value);
      return valueFormatted;
    };

    const downloadDocumentCell = (params) => {
      if (!params?.value) {
        return null;
      }
      const array = params.value;
      if (Array.isArray(array)) {
        return (
          <DropDownMenu
            menuId={"download-documents"}
            placeholder="Documents"
            menuItems={array.map((document, index) => {
              return (
                <div key={index}>
                  <FileDownloaderCell
                    document={document}
                    idx={index}
                  ></FileDownloaderCell>
                </div>
              );
            })}
          ></DropDownMenu>
        );
      }
      return (
        <FileDownloaderCell
          document={params.value}
          idx={0}
        ></FileDownloaderCell>
      );
    };

    const documentDownloadIcon = (params) => {
      if (!params?.value) {
        return null;
      }
      const array = params.value;
      if (Array.isArray(array)) {
        const handleMenuItemClick = (event) => {
          event.stopPropagation();
        };
        return (
          <DropDownMenu
            menuId={"download-documents-icon-menu"}
            dense={true}
            PaperSx={{
              backgroundColor: "info.main",
              color: "info.contrastText",
            }}
            placeholder={"Download"}
            menuItems={array.map((document, index) => {
              return (
                <MenuItem
                  onClick={handleMenuItemClick}
                  sx={{
                    cursor: "default",
                    "&:hover": {
                      backgroundColor: "transparent", // Remove hover effects
                    },
                  }}
                  dense={true}
                  key={index}
                  disableGutters
                  disableRipple
                  disableTouchRipple
                >
                  <FileDownloaderIcon
                    document={document}
                    documentName={document.fileName}
                    idx={index}
                    tooltipText={document.fileName}
                  ></FileDownloaderIcon>
                </MenuItem>
              );
            })}
          ></DropDownMenu>
        );
      } else if (params.value.fileName) {
        return (
          <FileDownloaderIcon
            document={params.value}
            idx={0}
            tooltipText={params.value.fileName}
          ></FileDownloaderIcon>
        );
      } else if (typeof params.value === "string") {
        return (
          <FileDownloaderIconURL
            url={params.value}
            idx={0}
            tooltipText={parse_aws_url(params.value)}
          ></FileDownloaderIconURL>
        );
      }
      return null;
    };
    // Define other utility functions...

    // Define the column modifiers array
    const columnModifiers = [
      customFormatters,
      customActionGetters,
      formatters,
      customCellRender,
      customAggregationFormatters,
      filterOperators,
      valueSetter,
      headerNameTranslator,
    ];

    // Process columns when the component mounts

    // Process the columns
    const processColumns = (columns) => {
      if (!columns?.length) return [];
      return columns.map((column) => {
        columnModifiers.forEach((func) => func(column));
        return column;
      });
    };
    setProcessedColumns(processColumns(columns));
  }, [columns, t, i18n.language, clientCurrency]);

  return { processedColumns };
};

export default useDataGridColumns;
