import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { RTKQueryWrapper } from "components/DataFetchWrapper/RTKQueryWrapper";
import { DataGridTable } from "components/Tables/DataGridTable";

const CheckResultDisplay = ({ runResult, checks, selectedCheck }) => {
  const [showRawData, setShowRawData] = useState(false);  // State to toggle raw or result details

  const handleToggle = (event) => {
    setShowRawData(event.target.checked);
  };

  const processData = (data) => {
    if (!data || typeof data !== 'object' || !data.rows || !data.columns) {
      return { rows: [], columns: [] };
    }
    return { rows: data.rows, columns: data.columns };
  };

  const formatLastUpdated = (dateString) => {
    console.log(dateString)
    if (!dateString) return "N/A"; // Handle undefined or null last_updated
    try {
      const formattedDate = new Date(dateString);
      return formattedDate.toLocaleString(); // Convert to a readable date-time string
    } catch (error) {
      return "Invalid Date";
    }
  };


  if (!runResult) return null;

  return (
    <Box sx={{ mt: 4 }}>
      <h2>Run Result for {checks.find(check => check.id === selectedCheck)?.name}:</h2>

      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100px',
            height: '50px',
            backgroundColor: runResult.check_passed ? '#92D050' : '#F92727',
            color: 'white',
            borderRadius: '12px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
            flexShrink: 0, // Prevent shrinking when adding the text next to it
          }}
        >
          {runResult.check_passed ? 'Passed' : 'Failed'}
        </Box>

        {/* Spacing and ensuring it stays on one line */}
        <Box
          sx={{
            ml: 20,
            fontSize: '16px',
            fontStyle: 'italic',
            whiteSpace: 'nowrap', // Prevent wrapping
          }}
        >
          Check last updated: {formatLastUpdated(runResult.last_updated)} (server time, UTC)
        </Box>
      </Box>

      {/* Toggle for raw vs result details */}
      <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
        <span>Show failed records</span>
        <FormControlLabel
          control={
            <Switch
              checked={showRawData}
              onChange={handleToggle}
              color="primary"
            />
          }
          labelPlacement="start"
        />
        <span>Show all records</span>
      </Box>

      {/* Data table */}
      <Box sx={{ mt: 4 }}>
        <RTKQueryWrapper query={{ data: showRawData ? runResult.raw_result_details : runResult.result_details }}>
          {(data) => (
            <DataGridTable
              rows={processData(data).rows}
              columns={processData(data).columns}
              pageSize={50}
              pagination={true}
              paginationMode="client"
              rowsPerPageOptions={[50]}
              id={"HealthCheckTable"}
            />
          )}
        </RTKQueryWrapper>
      </Box>
    </Box>
  );
};

export default CheckResultDisplay;