import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  FormControl,
  InputLabel,
  Checkbox,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Alert,
  List,
  ListItem,
  ListItemText,
  Grid,
} from '@mui/material';
import {
  useGetClientDetailsQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,
} from 'api/apiSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { api } from 'api/apiSlice'; // Updated import

const reportingIntervalMapping = {
  'DAYS-0': 'Daily',
  'WEEKLY-0': 'Weekly',
  'MONTHLY-0': 'Monthly',
  'QUARTERLY-0': 'Quarterly',
};

const reverseReportingIntervalMapping = {
  Daily: 'DAYS-0',
  Weekly: 'WEEKLY-0',
  Monthly: 'MONTHLY-0',
  Quarterly: 'QUARTERLY-0',
};

const ClientDetailsModal = ({ open, handleClose, clientId, mode, onSave, users }) => {
  const { data, error, isLoading } = useGetClientDetailsQuery(clientId, {
    skip: mode === 'create',
  });

  const [createClient] = useCreateClientMutation();
  const [updateClient] = useUpdateClientMutation();
  const [deleteClient] = useDeleteClientMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { t } = useTranslation();

  const [client, setClient] = useState({
    code: '',
    display_name: '',
    is_active: true,
    portfolio_ids: '',
    fa_contact_id: null,
    secondary_asset_class: null,
    reporting_interval: 'Daily',
    last_modified: '',
  });

  const [usersWithClient, setUsersWithClient] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setClient({
        code: data.code || '',
        display_name: data.display_name || '',
        is_active: data.is_active,
        portfolio_ids: data.portfolio_ids || '',
        asset_class: data.asset_class || '',
        secondary_asset_class: data.secondary_asset_class || '',
        reporting_interval:
          reportingIntervalMapping[data.reporting_interval] || 'Daily',
        last_modified: data.last_modified || '',
      });
    }
  }, [data]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (mode === 'edit' && users && clientId) {
        try {
          const userDetailsPromises = users.map((user) =>
            dispatch(
              api.endpoints.getClientUserDetails.initiate({ id: user.user.id })
            ).unwrap()
          );
          const userDetailsArray = await Promise.all(userDetailsPromises);
          const filteredUsers = userDetailsArray.filter((userDetail) =>
            userDetail.client_list?.some((client) => client.id === clientId)
          );
          setUsersWithClient(filteredUsers);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };

    fetchUserDetails();
  }, [mode, users, clientId, dispatch]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClient((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const isSaveDisabled = !client.code || !client.display_name || !client.portfolio_ids;

  const handleSave = async () => {
    const clientData = {
      ...client,
      reporting_interval: reverseReportingIntervalMapping[client.reporting_interval],
      ultimate_parent: client.portfolio_ids,
      portfolio_selection_method: 'parent_portfolio',
      asset_class: client.asset_class,
      calculation_engine: 'fa_analytics',
    };

    try {
      if (mode === 'create') {
        await createClient(clientData).unwrap();
      } else {
        await updateClient({ id: clientId, ...clientData }).unwrap();
      }
      await onSave(); // Callback to refresh the client list or perform other actions
      handleClose(); // Close the modal if successful
    } catch (err) {
      if (err?.data?.error) {
        setAlertMessage(err.data.error); // Display error message from response
      } else {
        console.error('Error saving client data:', err);
        setAlertMessage('An unexpected error occurred.');
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteClient({ id: clientId });
      await onSave(); // Refresh the client list
      handleClose(); // Close the modal
    } catch (err) {
      console.error('Error deleting client:', err);
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (error) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{error.message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('userManagement.clientModal.close')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {mode === 'create'
          ? t('userManagement.clientModal.createClientDetails')
          : t('userManagement.clientModal.editClientDetails')}
      </DialogTitle>
      <DialogContent>
        {alertMessage && (
          <Alert severity="error" onClose={() => setAlertMessage('')}>
            {alertMessage}
          </Alert>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* Form Fields */}
            <TextField
              margin="dense"
              label={t('userManagement.clientModal.code')}
              type="text"
              fullWidth
              name="code"
              value={client.code}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label={t('userManagement.clientModal.displayName')}
              type="text"
              fullWidth
              name="display_name"
              value={client.display_name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label={t('userManagement.clientModal.portfolioId')}
              type="text"
              fullWidth
              name="portfolio_ids"
              value={client.portfolio_ids}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label={t('userManagement.clientModal.assetClass')}
              type="text"
              fullWidth
              name="asset_class"
              value={client.asset_class || ''}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              label={t('userManagement.clientModal.secondaryAssetClass')}
              type="text"
              fullWidth
              name="secondary_asset_class"
              value={client.secondary_asset_class || ''}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel id="reporting-interval-label">
                {t('userManagement.clientModal.reportingInterval')}
              </InputLabel>
              <Select
                labelId="reporting-interval-label"
                id="reporting-interval"
                name="reporting_interval"
                value={client.reporting_interval}
                onChange={handleChange}
                label={t('userManagement.clientModal.reportingInterval')}
              >
                <MenuItem value="Daily">Daily</MenuItem>
                <MenuItem value="Weekly">Weekly</MenuItem>
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Quarterly">Quarterly</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_active"
                  checked={client.is_active}
                  onChange={handleChange}
                  disabled
                />
              }
              label={t('userManagement.clientModal.isActive')}
            />
            {mode !== 'create' && (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 16 }}>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={openDeleteDialog}
                >
                  <DeleteIcon />
                </IconButton>
                <Typography variant="body2">
                  {t('userManagement.clientModal.delete')}
                </Typography>
              </div>
            )}
          </Grid>
          {mode === 'edit' && (
            <Grid item xs={12} md={6}>
              {/* Users List */}
              <Typography variant="h6">
                {t('userManagement.clientModal.usersWithAccess')}
              </Typography>
              {usersWithClient.length > 0 ? (
                <List>
                  {usersWithClient.map((user) => {
                    const firstName = user.user.first_name || '';
                    const lastName = user.user.last_name || '';
                    const fullName = `${firstName} ${lastName}`.trim();
                    const displayName = fullName !== '' ? fullName : 'ukjent';

                    const email = user.user.email && user.user.email !== '' ? user.user.email : 'ukjent';

                    return (
                      <ListItem key={user.user.id}>
                        <ListItemText
                          primary={`${displayName} (${email})`}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              ) : (
                <Typography variant="body2">
                  {t('userManagement.clientModal.noUsersWithAccess')}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('userManagement.clientModal.cancel')}</Button>
        <Button onClick={handleSave} color="primary" disabled={isSaveDisabled}>
          {mode === 'create'
            ? t('userManagement.clientModal.create')
            : t('userManagement.clientModal.save')}
        </Button>
      </DialogActions>
      <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogTitle>{t('userManagement.clientModal.deleteConfirmation')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('userManagement.clientModal.deleteConfirmationMessage')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>{t('userManagement.clientModal.cancel')}</Button>
          <Button onClick={handleDelete} color="secondary">
            {t('userManagement.clientModal.deleteButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default ClientDetailsModal;
