import React from "react";
import { PlotlyChart } from "../../../components/PlotlyChart";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function ChartOne({ data, ...props }) {
  const footnote = data?.chart?.chart_config?.footnote;
  return data ? (
    <Box sx={{ height: "65vh" }}>
      <PlotlyChart
        chart_data={data?.chart?.chart_data}
        chart_config={data?.chart?.chart_config}
        light={false}
        {...props}
      ></PlotlyChart>
      {footnote && (
        <Typography variant="body2" color="textSecondary" sx={{ marginTop: "10px", paddingLeft: "16px" }}>
          {footnote}
        </Typography>
      )}
    </Box>
  ) : null;
}
