import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { nb } from 'date-fns/locale';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  useGetDataQualityChecksQuery,
  useCreateDataQualitySubscriptionMutation,
  useUpdateDataQualitySubscriptionMutation,
  useDeleteDataQualitySubscriptionMutation,
} from "api/apiSlice";

const CreateSubscriptionModal = ({ open, handleClose, refetch, subscription }) => {
  const chosenClient = useSelector((state) => state.user.chosen_client);
  const [selectedClient, setSelectedClient] = useState(chosenClient?.id || "");
  const [selectedCheck, setSelectedCheck] = useState("");
  const [parameters, setParameters] = useState({});
  const [parameterValues, setParameterValues] = useState({});
  const [daysToRun, setDaysToRun] = useState(["MO", "TU", "WE", "TH", "FR"]);
  const [runTime, setRunTime] = useState("06:00");
  const [selectedCheckDescription, setSelectedCheckDescription] = useState("");

  const { data: checks = [], isLoading, error } = useGetDataQualityChecksQuery();
  const [createDataQualitySubscription] = useCreateDataQualitySubscriptionMutation();
  const [updateDataQualitySubscription] = useUpdateDataQualitySubscriptionMutation();
  const [deleteDataQualitySubscription] = useDeleteDataQualitySubscriptionMutation();

  useEffect(() => {
    if (subscription) {
      setSelectedClient(subscription.client_details?.id || "");
      setSelectedCheck(subscription.data_quality_check_details?.id || "");
      setDaysToRun(subscription.days_to_run?.split(", ") || ["MO", "TU", "WE", "TH", "FR"]);
      setRunTime(subscription.run_time?.slice(0, 5) || "06:00");

      const selectedCheckObj = checks.find((check) => check.id === subscription.data_quality_check_details?.id);

      if (selectedCheckObj) {
        const parameterSettings = selectedCheckObj.parameters || {};
        const initialParameterValues = {};

        const parsedSubscriptionParams = JSON.parse(subscription.parameters || "{}");

        Object.keys(parameterSettings).forEach((paramName) => {
          initialParameterValues[paramName] = parameterSettings[paramName].default || "";
        });

        Object.keys(parsedSubscriptionParams).forEach((paramName) => {
          if (parsedSubscriptionParams[paramName] !== undefined) {
            initialParameterValues[paramName] = parsedSubscriptionParams[paramName];
          }
        });

        setParameters(parameterSettings);
        setParameterValues(initialParameterValues);
      }

      setSelectedCheckDescription(subscription.data_quality_check_details?.description || "");
    } else {
      setSelectedClient(chosenClient?.id || "");
      setSelectedCheck("");
      setParameters({});
      setParameterValues({});
      setDaysToRun(["MO", "TU", "WE", "TH", "FR"]);
      setRunTime("06:00");
      setSelectedCheckDescription("");
    }
  }, [subscription, open, chosenClient, checks]);

  const handleCheckChange = (event) => {
    const selectedId = event.target.value;
    setSelectedCheck(selectedId);

    const selectedCheckObj = checks.find((check) => check.id === selectedId);
    if (selectedCheckObj) {
      setSelectedCheckDescription(selectedCheckObj.description || "");

      const initialParameterValues = {};
      Object.keys(selectedCheckObj.parameters).forEach((paramName) => {
        initialParameterValues[paramName] = selectedCheckObj.parameters[paramName].default || "";
      });

      setParameters(selectedCheckObj.parameters || {});
      setParameterValues(initialParameterValues);
    }
  };

  const handleParameterChange = (paramName, value) => {
    setParameterValues((prevValues) => ({
      ...prevValues,
      [paramName]: value,
    }));
  };

  const handleDaysToRunChange = (day) => {
    setDaysToRun((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleSaveSubscription = async () => {
    const payload = {
      client: selectedClient,
      data_quality_check: selectedCheck,
      parameters: parameterValues,
      days_to_run: Array.isArray(daysToRun) ? daysToRun : daysToRun.split(",").map(day => day.trim()),
      run_time: runTime,
    };

    try {
      if (subscription) {
        await updateDataQualitySubscription({ id: subscription.id, ...payload }).unwrap();
      } else {
        await createDataQualitySubscription(payload).unwrap();
      }
      refetch();
      handleClose();
    } catch (error) {
      console.error("Failed to save subscription:", error);
    }
  };

  const handleDeleteSubscription = async () => {
    if (subscription && subscription.id) {
      try {
        await deleteDataQualitySubscription(subscription.id).unwrap();
        refetch();
        handleClose();
      } catch (error) {
        console.error("Failed to delete subscription:", error);
      }
    }
  };

  const renderParameterInput = (paramName, paramMeta) => {
    switch (paramMeta.type) {
      case "date": {
        const dateValue = parameterValues[paramName]
          ? new Date(parameterValues[paramName])
          : null;
        return (
          <TextField
            label={paramMeta.label || paramName}
            type="date"
            value={dateValue ? dateValue.toISOString().substring(0, 10) : ""}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      }

      case "string": {
        return (
          <TextField
            label={paramMeta.label || paramName}
            value={parameterValues[paramName] || ""}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      }

      case "number": {
        return (
          <TextField
            label={paramMeta.label || paramName}
            type="number"
            value={parameterValues[paramName] || ""}
            onChange={(e) => handleParameterChange(paramName, e.target.value)}
            fullWidth
          />
        );
      }

      default:
        return null;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nb}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="create-subscription-modal"
        aria-describedby="create-subscription-form"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <h2 id="create-subscription-modal">
            {subscription ? "Edit Subscription" : "Create New Subscription"}
          </h2>

          <TextField
            label="Selected Client"
            value={chosenClient?.display_name || "N/A"}
            fullWidth
            margin="normal"
            disabled
          />

          <TextField
            select
            label="Select Data Quality Check"
            value={selectedCheck}
            onChange={handleCheckChange}
            fullWidth
            margin="normal"
            disabled={isLoading || error}
          >
            {!isLoading && !error && checks.length > 0 ? (
              checks.map((check) => (
                <MenuItem key={check.id} value={check.id}>
                  {check.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                {isLoading ? "Loading..." : "No checks available"}
              </MenuItem>
            )}
          </TextField>

          {selectedCheckDescription && (
            <TextField
              label="Description"
              value={selectedCheckDescription}
              fullWidth
              margin="normal"
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          )}

          <Box sx={{ mt: 2 }}>
            <h4>Select Days to Run:</h4>
            {["MO", "TU", "WE", "TH", "FR", "SA", "SU"].map((day, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={daysToRun.includes(day)}
                    onChange={() => handleDaysToRunChange(day)}
                    name={day}
                  />
                }
                label={day}
              />
            ))}
          </Box>

          {Object.keys(parameters).length > 0 && (
            <Box sx={{ mt: 2 }}>
              <h4>Check Parameters</h4>
              {Object.keys(parameters).map((paramName) => (
                <Box sx={{ mb: 2 }} key={paramName}>
                  {renderParameterInput(paramName, parameters[paramName])}
                </Box>
              ))}
            </Box>
          )}

          <TextField
            select
            label="Run Time (UTC)"
            value={runTime}
            onChange={(e) => setRunTime(e.target.value)}
            fullWidth
            margin="normal"
          >
            {Array.from({ length: 24 }, (_, i) => (
              <MenuItem key={i} value={`${i.toString().padStart(2, "0")}:00`}>
                {`${i.toString().padStart(2, "0")}:00 UTC`}
              </MenuItem>
            ))}
          </TextField>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveSubscription}
            >
              {subscription ? "Update Subscription" : "Create Subscription"}
            </Button>

            {subscription && (
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteSubscription}
              >
                Delete Subscription
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </LocalizationProvider>
  );
};

export default CreateSubscriptionModal;
