import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Typography,
} from '@mui/material';
import { Add, Remove, ArrowDownward } from '@mui/icons-material';
import { useGetClientUserDetailsQuery, useCreateClientUserMutation, useUpdateClientUserMutation, useDeleteClientUserMutation, useGetCreateUserOptionsQuery } from 'api/apiSlice';
import RTKQueryWrapper from 'components/DataFetchWrapper/RTKQueryWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";

const UserDetailsModal = ({ open, handleClose, userId, mode, onSave }) => {
  const { data, error, isLoading, refetch, ...userDetailsQuery } = useGetClientUserDetailsQuery(
    { id: userId },
    { skip: mode === 'create' }
  );
  const { t } = useTranslation();

  const { data: createUserOptions, isLoading: isOptionsLoading, error: optionsError } = useGetCreateUserOptionsQuery();
  const [createUser] = useCreateClientUserMutation();
  const [updateUser] = useUpdateClientUserMutation();
  const [deleteUser] = useDeleteClientUserMutation();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  
  const dialogContentRef = useRef(null);  // Ref to scroll to top on error
  const scrollIndicatorRef = useRef(null);  // Ref to control visibility of the scroll indicator

  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    is_staff: false,
    is_active: true,
    pages: [],
    client_list: [],
    max_date_calculation_override: null,
    password: '',
  });

  const [filteredAvailablePages, setFilteredAvailablePages] = useState([]);
  const [filteredAvailableClients, setFilteredAvailableClients] = useState([]);
  const [originalUsername, setOriginalUsername] = useState('');

  useEffect(() => {
    if (data) {
      setUser({
        username: data.user.username || '',
        first_name: data.user.first_name || '',
        last_name: data.user.last_name || '',
        email: data.user.email || '',
        is_staff: data.user.is_staff || false,
        is_active: data.user.is_active, // Directly set from data without conditions
        pages: data.settings.pages || [],
        client_list: data.client_list || [],
        max_date_calculation_override: data.settings.max_date_calculation_override || null,
        password: '', // Reset password field for security
      });
      setOriginalUsername(data.user.username);
    }
  }, [data]);

  useEffect(() => {
    if (createUserOptions) {
      const preselectedPages = createUserOptions.available_pages.filter(
        (page) => page.selected
      );

      const availablePages = createUserOptions.available_pages.filter(
        (page) => !preselectedPages.some((preselectedPage) => preselectedPage.id === page.id)
      );

      const availableClients = createUserOptions.clients.filter(
        (client) => !user.client_list.some((userClient) => userClient.id === client.id)
      );

      setUser((prev) => ({
        ...prev,
        pages: mode === 'create' ? preselectedPages : prev.pages,
      }));

      setFilteredAvailablePages(availablePages);
      setFilteredAvailableClients(availableClients);
    }
  }, [createUserOptions, user.client_list, mode]);

  const handleUserChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleAddPage = (page) => {
    setUser((prev) => ({
      ...prev,
      pages: [...prev.pages, page]
    }));
  };

  const handleRemovePage = (page) => {
    setUser((prev) => ({
      ...prev,
      pages: prev.pages.filter(p => p.id !== page.id)
    }));
  };

  const handleAddClient = (client) => {
    setUser((prev) => ({
      ...prev,
      client_list: [...prev.client_list, client]
    }));
  };

  const handleRemoveClient = (client) => {
    setUser((prev) => ({
      ...prev,
      client_list: prev.client_list.filter(c => c.id !== client.id)
    }));
  };

  const handleSave = async () => {
    const payload = {
      ...user,
      pages: user.pages.length > 0 ? user.pages.map(page => page.id).join(',') : undefined,
      client_list: user.client_list.length > 0 ? user.client_list.map(client => client.id).join(',') : undefined,
    };

    if (mode !== 'create' && user.username === originalUsername) {
      delete payload.username;
    }

    try {
      if (mode === 'create') {
        await createUser(payload).unwrap();
      } else {
        await updateUser({ id: userId, ...payload }).unwrap();
      }
      await onSave();
      handleClose(); 
    } catch (err) {
      if (err?.data?.error) {
        setAlertMessage(err.data.error); 
        dialogContentRef.current.scrollTop = 0; // Scroll to top on error
      } else {
        console.error(err);
        setAlertMessage("An unexpected error occurred.");
        dialogContentRef.current.scrollTop = 0; 
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteUser({ id: userId });
      await onSave(); // Callback to refresh the user list or do other actions
      handleClose(); // Close the modal
    } catch (err) {
      console.error("Error deleting user:", err);
    }
  };

  const openDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const isSaveDisabled = mode === 'create' 
    ? !user.first_name || !user.last_name || !user.password || user.pages.length === 0 || user.client_list.length === 0 
    : !user.first_name || !user.last_name || user.pages.length === 0 || user.client_list.length === 0;

  useEffect(() => {
    const handleScroll = () => {
      if (dialogContentRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          scrollIndicatorRef.current.style.opacity = '0'; // Hide the indicator when at the bottom
        } else {
          scrollIndicatorRef.current.style.opacity = '1'; // Show the indicator otherwise
        }
      }
    };

    const dialogContent = dialogContentRef.current;
    if (dialogContent) {
      dialogContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (dialogContent) {
        dialogContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  if (isLoading || isOptionsLoading) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (error || optionsError) {
    return (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>{(error || optionsError).message}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <RTKQueryWrapper query={{ data, error, isLoading }}>
        {() => (
          <>
            <DialogTitle>{mode === 'create' ? t("userManagement.clientUserModal.createUserDetails") : t("userManagement.clientUserModal.editUserDetails")}</DialogTitle>
            <DialogContent ref={dialogContentRef} style={{ maxHeight: '60vh', overflowY: 'auto', position: 'relative' }}>
              {alertMessage && (
                <Alert severity="error" onClose={() => setAlertMessage('')}>
                  {alertMessage}
                </Alert>
              )}
              <TextField
                margin="dense"
                label={t("userManagement.clientUserModal.email")}
                type="email"
                fullWidth
                name="email"
                value={user.email}
                onChange={handleUserChange}
              />
              <TextField
                margin="dense"
                label={t("userManagement.clientUserModal.firstName")}
                type="text"
                fullWidth
                name="first_name"
                value={user.first_name}
                onChange={handleUserChange}
              />
              <TextField
                margin="dense"
                label={t("userManagement.clientUserModal.lastName")}
                type="text"
                fullWidth
                name="last_name"
                value={user.last_name}
                onChange={handleUserChange}
              />
              {mode === 'create' && (
                <TextField
                  margin="dense"
                  label={t("userManagement.clientUserModal.password")}
                  type="password"
                  fullWidth
                  name="password"
                  value={user.password}
                  onChange={handleUserChange}
                />
              )}
              <FormControl fullWidth margin="dense">
                {user.max_date_calculation_override === null && (
                  <InputLabel id="max-date-calculation-interval-label">{t("userManagement.clientUserModal.maxDateCalculationInterval")}</InputLabel>
                )}
                <Select
                  labelId="max-date-calculation-interval-label"
                  id="max-date-calculation-interval"
                  name="max_date_calculation_override"
                  value={user.max_date_calculation_override || ''}
                  onChange={handleUserChange}
                  label={user.max_date_calculation_override === null ? 'Max date calculation interval' : ''}
                >
                  <MenuItem value={null}>None</MenuItem>
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>
                  <MenuItem value="start_last_month">Start of last month</MenuItem>
                  <MenuItem value="end_last_month">End of last month</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_staff"
                    checked={user.is_staff}
                    onChange={handleUserChange}
                    
                  />
                }
                label={t("userManagement.clientUserModal.isStaff")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_active"
                    checked={user.is_active}
                    onChange={handleUserChange}
                    
                  />
                }
                label={t("userManagement.clientUserModal.isActive")}
              />
              <div style={{ display: 'flex', marginTop: 16 }}>
                <div style={{ flex: 1, marginRight: 8, border: '1px solid lightgray', borderRadius: 8, padding: 8 }}>
                  <h3>{t("userManagement.clientUserModal.availablePages")}</h3>
                  <List style={{ maxHeight: 200, overflow: 'auto' }}>
                    {filteredAvailablePages.map((page) => (
                      <ListItem key={page.id}>
                        <ListItemText primary={page.display_name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="add" onClick={() => handleAddPage(page)}>
                            <Add />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div style={{ flex: 1, marginLeft: 8, border: '1px solid lightgray', borderRadius: 8, padding: 8 }}>
                  <h3>{t("userManagement.clientUserModal.selectedPages")}</h3>
                  <List style={{ maxHeight: 200, overflow: 'auto' }}>
                    {user.pages.map((page) => (
                      <ListItem key={page.id}>
                        <ListItemText primary={page.display_name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="remove" onClick={() => handleRemovePage(page)}>
                            <Remove />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
              <div style={{ display: 'flex', marginTop: 16 }}>
                <div style={{ flex: 1, marginRight: 8, border: '1px solid lightgray', borderRadius: 8, padding: 8 }}>
                  <h3>{t("userManagement.clientUserModal.availableClients")}</h3>
                  <List style={{ maxHeight: 200, overflow: 'auto' }}>
                    {filteredAvailableClients.map((client) => (
                      <ListItem key={client.id}>
                        <ListItemText primary={client.display_name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="add" onClick={() => handleAddClient(client)}>
                            <Add />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
                <div style={{ flex: 1, marginLeft: 8, border: '1px solid lightgray', borderRadius: 8, padding: 8 }}>
                  <h3>{t("userManagement.clientUserModal.selectedClients")}</h3>
                  <List style={{ maxHeight: 200, overflow: 'auto' }}>
                    {user.client_list.map((client) => (
                      <ListItem key={client.id}>
                        <ListItemText primary={client.display_name} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="remove" onClick={() => handleRemoveClient(client)}>
                            <Remove />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div>
            </DialogContent>
            <div
              ref={scrollIndicatorRef}
              style={{
                position: 'absolute',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                textAlign: 'center',
                opacity: '1',
                transition: 'opacity 0.3s',
                pointerEvents: 'none',
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {t("userManagement.clientUserModal.scrollDown")}
               
              </Typography>
              <ArrowDownward color="disabled" />
            </div>
            {mode !== 'create' && (
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                <IconButton
                  aria-label="delete"
                  color="secondary"
                  onClick={openDeleteDialog}
                  style={{ marginLeft: 15 }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            )}
            <DialogActions>
              <Button onClick={handleClose}>{t("userManagement.clientUserModal.cancel")}</Button>
              <Button onClick={handleSave} color="primary" disabled={isSaveDisabled}>
                {mode === 'create' ? t("userManagement.clientUserModal.create") : t("userManagement.clientUserModal.save")}
              </Button>
            </DialogActions>
            <Dialog open={isDeleteDialogOpen} onClose={closeDeleteDialog}>
              <DialogTitle>{t("userManagement.clientUserModal.delete")}</DialogTitle>
              <DialogActions>
                <Button onClick={closeDeleteDialog}>{t("userManagement.clientUserModal.cancel")}</Button>
                <Button onClick={handleDelete} color="secondary">{t("userManagement.clientUserModal.deleteButton")}</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </RTKQueryWrapper>
    </Dialog>
  );
};

export default UserDetailsModal;
