import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState: {
    message: "",
    type: "info", // or error, success, warning
  },
  reducers: {
    enqueueSnackbar: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    closeSnackbar: (state) => {
      state.message = "";
      state.type = "info";
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      // Matcher for any rejected action
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        // Add a notification for each rejected action
        state.message = action.error.message || "An error occurred";
        state.type = "error";
      }
    );
  },
});

export const { enqueueSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
